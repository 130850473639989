import type { Config } from 'tailwindcss';
import plugin from 'tailwindcss/plugin';

const screenSizes = {
  '2xl': 1535,
  xl: 1279,
  lg: 1023,
  md: 767,
  sm: 639,
  xs: 511,
  xxs: 383,
  text: 560,
  'extra-small': '27rem',
  small: '48rem',
  medium: '66rem',
  large: '90rem',
  'extra-large': '120rem'
};
export default {
  darkMode: 'class',
  content: [
    './app/**/*.{js,ts,jsx,tsx}',
    './pages/**/*.{js,ts,jsx,tsx}',
    './components/**/*.{js,ts,jsx,tsx}',
    './layouts/**/*.{js,ts,jsx,tsx}',
    './utils/**/*.{js,ts,jsx,tsx}'
  ],
  theme: {
    screens: {
      '2xl': { max: screenSizes['2xl'] + 'px' },
      // => @media (max-width: 1535px) { ... }

      xl: { max: screenSizes.xl + 'px' },
      // => @media (max-width: 1279px) { ... }

      lg: { max: screenSizes.lg + 'px' },
      // => @media (max-width: 1023px) { ... }

      md: { max: screenSizes.md + 'px' },
      // => @media (max-width: 767px) { ... }

      sm: { max: screenSizes.sm + 'px' },
      // => @media (max-width: 639px) { ... }

      xs: { max: screenSizes.xs + 'px' },
      // => @media (max-width: 511px) { ... }

      xxs: { max: screenSizes.xxs + 'px' },
      // => @media (max-width: 383px) { ... }

      'extra-small': { max: screenSizes['extra-small'] },
      small: { max: screenSizes['small'] },
      medium: { max: screenSizes['medium'] },
      large: { max: screenSizes['large'] },
      'extra-large': { max: screenSizes['extra-large'] }
    },
    colors: {
      common: {
        black: '#000000',
        white: '#ffffff'
      },
      transparent: 'transparent',
      yellow: {
        darkest: '#CC9C20',
        darker: '#E5AF24',
        100: '#FFC328',
        50: '#FFE9B1',
        25: '#FFF7E3'
      },
      orange: {
        100: '#F26C0C',
        50: '#FFD7BA',
        25: '#FFF1E7'
      },
      blue: {
        100: '#3677FF',
        50: '#CEDCFF',
        25: '#EDF2FC'
      },
      green: {
        100: '#5FD48E',
        50: '#B9FAC5',
        25: '#EAF9ED'
      },
      gray: {
        100: '#59564F',
        80: '#9F9B91',
        60: '#D1CDC4',
        40: '#EAE6E0',
        20: '#F2F0EB',
        10: '#F7F6F2'
      },
      purple: {
        normal: '#56004D',
        dark: '#470e42'
      },
      system: {
        red: {
          100: '#DA0000',
          20: '#f5d5d0',
          10: '#f7e6e5'
        },
        focus: '#00A3FF'
      },
      opacity: {
        black: {
          16: '#0A080229',
          12: '#0A08021F',
          8: '#0A080214'
        },
        yellow: {
          16: '#FFC32829',
          12: '#FFC3281f',
          8: '#FFC32814'
        }
      },
      background: {
        primary: {
          default: 'var(--color-background-primary)',
          active: 'var(--color-background-active-primary)',
          hover: 'var(--color-background-hover-primary)'
        },
        secondary: {
          default: 'var(--color-background-secondary)',
          active: 'var(--color-background-active-secondary)',
          hover: 'var(--color-background-hover-secondary)'
        },
        dimmed: {
          default: 'var(--color-background-dimmed)',
          active: 'var(--color-background-active-dimmed)',
          hover: 'var(--color-background-hover-dimmed)'
        },
        accent: {
          default: 'var(--color-background-accent)',
          active: 'var(--color-background-active-accent)',
          hover: 'var(--color-background-hover-accent)'
        },
        disabled: 'var(--color-background-disabled)'
      },
      foreground: {
        primary: 'var(--color-foreground-primary)',
        secondary: 'var(--color-foreground-secondary)',
        dimmed: 'var(--color-foreground-dimmed)',
        accent: 'var(--color-foreground-accent)',
        disabled: 'var(--color-foreground-disabled)'
      },
      status: {
        positive: {
          default: 'var(--color-status-positive)',
          dimmed: 'var(--color-status-positive-dimmed	)'
        },
        negative: {
          default: 'var(--color-status-negative)',
          dimmed: 'var(--color-status-negative-dimmed)'
        },
        informative: {
          default: 'var(--color-status-informative)',
          dimmed: 'var(--color-status-informative-dimmed)'
        },
        waiting: {
          default: 'var(--color-status-waiting)',
          dimmed: 'var(--color-status-waiting-dimmed)'
        }
      },
      palette: {
        yellow: {
          100: 'var(--color-palette-yellow-100)',
          200: 'var(--color-palette-yellow-200)',
          300: 'var(--color-palette-yellow-300)',
          400: 'var(--color-palette-yellow-400)',
          500: 'var(--color-palette-yellow-500)',
          600: 'var(--color-palette-yellow-600)',
          700: 'var(--color-palette-yellow-700)',
          800: 'var(--color-palette-yellow-800)',
          900: 'var(--color-palette-yellow-900)'
        },
        blue: {
          100: 'var(--color-palette-blue-100)',
          200: 'var(--color-palette-blue-200)',
          300: 'var(--color-palette-blue-300)',
          400: 'var(--color-palette-blue-400)',
          500: 'var(--color-palette-blue-500)',
          600: 'var(--color-palette-blue-600)',
          700: 'var(--color-palette-blue-700)',
          800: 'var(--color-palette-blue-800)',
          900: 'var(--color-palette-blue-900)'
        },
        orange: {
          100: 'var(--color-palette-orange-100)',
          200: 'var(--color-palette-orange-200)',
          300: 'var(--color-palette-orange-300)',
          400: 'var(--color-palette-orange-400)',
          500: 'var(--color-palette-orange-500)',
          600: 'var(--color-palette-orange-600)',
          700: 'var(--color-palette-orange-700)',
          800: 'var(--color-palette-orange-800)',
          900: 'var(--color-palette-orange-900)'
        },
        green: {
          100: 'var(--color-palette-green-100)',
          200: 'var(--color-palette-green-200)',
          300: 'var(--color-palette-green-300)',
          400: 'var(--color-palette-green-400)',
          500: 'var(--color-palette-green-500)',
          600: 'var(--color-palette-green-600)',
          700: 'var(--color-palette-green-700)',
          800: 'var(--color-palette-green-800)',
          900: 'var(--color-palette-green-900)'
        },
        gray: {
          100: 'var(--color-palette-gray-100)',
          200: 'var(--color-palette-gray-200)',
          300: 'var(--color-palette-gray-300)',
          400: 'var(--color-palette-gray-400)',
          500: 'var(--color-palette-gray-500)',
          600: 'var(--color-palette-gray-600)',
          700: 'var(--color-palette-gray-700)',
          800: 'var(--color-palette-gray-800)',
          900: 'var(--color-palette-gray-900)'
        },
        white: 'var(--color-palette-white)',
        black: 'var(--color-palette-black)'
      }
    },
    extend: {
      keyframes: {
        fadeIn: {
          '0%': {
            opacity: '0'
          },
          '100%': {
            opacity: '100'
          }
        },
        fadeOut: {
          '0%': {
            opacity: '100'
          },
          '100%': {
            opacity: '0'
          }
        },
        slideUpEnter: {
          '0%': {
            transform: 'translateY(20px)'
          },
          '100%': {
            transform: 'translateY(0px)'
          }
        },
        slideDownEnter: {
          '0%': {
            transform: 'translateY(0px)'
          },
          '100%': {
            transform: 'translateY(20px)'
          }
        },
        slideLeftEnter: {
          '0%': {
            transform: 'translateX(300px)'
          },
          '100%': {
            transform: 'translateX(0px)'
          }
        },
        slideRightEnter: {
          '0%': {
            transform: 'translateX(-300px)'
          },
          '100%': {
            transform: 'translateX(0px)'
          }
        },
        slideUpLeave: {
          '0%': {
            transform: 'translateY(0)'
          },
          '100%': {
            transform: 'translateY(-300px)'
          }
        },
        slideDownLeave: {
          '0%': {
            transform: 'translateY(0)'
          },
          '100%': {
            transform: 'translateY(300px)'
          }
        },
        slideLeftLeave: {
          '0%': {
            transform: 'translateX(0)'
          },
          '100%': {
            transform: 'translateX(-300px)'
          }
        },
        slideRightLeave: {
          '0%': {
            transform: 'translateX(0)'
          },
          '100%': {
            transform: 'translateX(300px)'
          }
        },
        radixAccordionSlideDown: {
          from: { height: '0' },
          to: { height: 'var(--radix-accordion-content-height)' }
        },
        radixAccordionSlideUp: {
          from: { height: 'var(--radix-accordion-content-height)' },
          to: { height: '0' }
        }
      },
      animation: {
        fadeIn: 'fadeIn 0.3s ease-in-out',
        fadeOut: 'fadeOut 0.3s ease-in-out',
        slideUpEnter: 'slideUpEnter 0.3s ease-in-out',
        slideLeftEnter: 'slideLeftEnter 0.3s ease-in-out',
        slideRightEnter: 'slideRightEnter 0.3s ease-in-out',
        slideDownEnter: 'slideDownEnter 0.3s ease-in-out',
        slideUpLeave: 'slideUpLeave 0.3s ease-in-out',
        slideLeftLeave: 'slideLeftLeave 0.3s ease-in-out',
        slideRightLeave: 'slideRightLeave 0.3s ease-in-out',
        slideDownLeave: 'slideDownLeave 0.3s ease-in-out',
        radixAccordionSlideDown: 'slideDown 0.3s ease-in-out',
        radixAccordionSlideUp: 'slideUp 0.3s ease-in-out'
      },
      minHeight: {
        12: '3rem'
      },
      width: {
        '2xl': screenSizes['2xl'] + 'px',
        xl: screenSizes.xl + 'px',
        lg: screenSizes.lg + 'px',
        md: screenSizes.md + 'px',
        sm: screenSizes.sm + 'px',
        xs: screenSizes.xs + 'px',
        xxs: screenSizes.xxs + 'px',
        text: screenSizes.text + 'px'
      },
      minWidth: {
        '2xl': screenSizes['2xl'] + 'px',
        xl: screenSizes.xl + 'px',
        lg: screenSizes.lg + 'px',
        md: screenSizes.md + 'px',
        sm: screenSizes.sm + 'px',
        xs: screenSizes.xs + 'px',
        xxs: screenSizes.xxs + 'px'
      },
      maxWidth: {
        '2xl': screenSizes['2xl'] + 'px',
        xl: screenSizes.xl + 'px',
        lg: screenSizes.lg + 'px',
        md: screenSizes.md + 'px',
        sm: screenSizes.sm + 'px',
        xs: screenSizes.xs + 'px',
        xxs: screenSizes.xxs + 'px',
        text: screenSizes.text + 'px'
      },
      fontFamily: {
        sansSerif: ['sans-serif', 'Planar', 'Helvetica', 'Arial']
      },
      fontWeight: {
        light: '300',
        regular: '400',
        medium: '500',
        heavy: '700'
      },
      fontSize: {
        '1300-d': [
          '4.125rem',
          {
            lineHeight: '130%'
          }
        ],
        '1200-d': [
          '3.625rem',
          {
            lineHeight: '130%'
          }
        ],
        '1100-d': [
          '3.25rem',
          {
            lineHeight: '130%'
          }
        ],
        '1000-d': [
          '2.875rem',
          {
            lineHeight: '130%'
          }
        ],
        '900-d': [
          '2.5625rem',
          {
            lineHeight: '130%'
          }
        ],
        '800-d': [
          '2.25rem',
          {
            lineHeight: '130%'
          }
        ],
        '700-d': [
          '2rem',
          {
            lineHeight: '130%'
          }
        ],
        '600-d': [
          '1.8125rem',
          {
            lineHeight: '130%'
          }
        ],
        '500-d': [
          '1.625rem',
          {
            lineHeight: '130%'
          }
        ],
        '400-d': [
          '1.4375rem',
          {
            lineHeight: '130%'
          }
        ],
        '300-d': [
          '1.25rem',
          {
            lineHeight: '130%'
          }
        ],
        '200-d': [
          '1.125rem',
          {
            lineHeight: '130%'
          }
        ],
        '100-d': [
          '1rem',
          {
            lineHeight: '150%'
          }
        ],
        '75-d': [
          '0.875rem',
          {
            lineHeight: '130%'
          }
        ],
        '50-d': [
          '0.875rem',
          {
            lineHeight: '130%'
          }
        ],
        '1300-m': [
          '4.625rem',
          {
            lineHeight: '130%'
          }
        ],
        '1200-m': [
          '4.125rem',
          {
            lineHeight: '130%'
          }
        ],
        '1100-m': [
          '3.625rem',
          {
            lineHeight: '130%'
          }
        ],
        '1000-m': [
          '3.25rem',
          {
            lineHeight: '130%'
          }
        ],
        '900-m': [
          '2.875rem',
          {
            lineHeight: '130%'
          }
        ],
        '800-m': [
          '2.5625rem',
          {
            lineHeight: '130%'
          }
        ],
        '700-m': [
          '2.25rem',
          {
            lineHeight: '130%'
          }
        ],
        '600-m': [
          '2rem',
          {
            lineHeight: '130%'
          }
        ],
        '500-m': [
          '1.8125rem',
          {
            lineHeight: '130%'
          }
        ],
        '400-m': [
          '1.625rem',
          {
            lineHeight: '130%'
          }
        ],
        '300-m': [
          '1.4375rem',
          {
            lineHeight: '130%'
          }
        ],
        '200-m': [
          '1.25rem',
          {
            lineHeight: '130%'
          }
        ],
        '100-m': [
          '1.125rem',
          {
            lineHeight: '130%'
          }
        ],
        '75-m': [
          '1rem',
          {
            lineHeight: '130%'
          }
        ],
        '50-m': [
          '0.875rem',
          {
            lineHeight: '130%'
          }
        ]
      },
      backgroundImage: {
        gradient: 'linear-gradient(0deg, var(--tw-gradient-stops))'
      },
      borderRadius: {
        'extra-small': 'var(--layout-border-radius-extra-small)',
        small: 'var(--layout-border-radius-small)',
        medium: 'var(--layout-border-radius-medium)',
        large: 'var(--layout-border-radius-large)'
      },
      borderWidth: {
        small: 'var(--layout-border-width-small)',
        medium: 'var(--layout-border-width-medium)',
        large: 'var(--layout-border-width-large)'
      },
      borderColor: {
        primary: 'var(--color-border-primary)',
        secondary: 'var(--color-border-secondary)',
        dimmed: 'var(--color-border-dimmed)',
        accent: 'var(--color-border-accent)',
        disabled: 'var(--color-border-disabled)',
        divider: 'var(--color-border-divider)',
        focus: 'var(--color-border-focus)',
        error: 'var(--color-border-error)'
      },
      spacing: {
        'extra-small': 'var(--layout-spacing-extra-small)',
        small: 'var(--layout-spacing-small)',
        medium: 'var(--layout-spacing-medium)',
        large: 'var(--layout-spacing-large)',
        'extra-large': 'var(--layout-spacing-extra-large)',
        'extra-large2': 'var(--layout-spacing-extra-large2)',
        'extra-large3': 'var(--layout-spacing-extra-large3)',
        'extra-large4': 'var(--layout-spacing-extra-large4)',
        'extra-large5': 'var(--layout-spacing-extra-large5)',
        'extra-large6': 'var(--layout-spacing-extra-large6)',
        'extra-large7': 'var(--layout-spacing-extra-large7)'
      }
    }
  },
  plugins: [
    plugin(({ addUtilities }) => {
      addUtilities({
        '.font-slant-neg45': { fontVariationSettings: '"slnt" -45' },
        '.font-slant-neg30': { fontVariationSettings: '"slnt" -30' },
        '.font-slant-neg15': { fontVariationSettings: '"slnt" -15' },
        '.font-slant-0': { fontVariationSettings: '"slnt" 0' },
        '.font-slant-pos15': { fontVariationSettings: '"slnt" 15' },
        '.font-slant-pos30': { fontVariationSettings: '"slnt" 30' },
        '.font-slant-pos45': { fontVariationSettings: '"slnt" 45' }
      });
    })
  ]
} satisfies Config;
