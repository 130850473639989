import { ClassValue, clsx } from 'clsx';
import { extendTailwindMerge } from 'tailwind-merge';
import { EstablishmentTypeEnum, SubscriptionDTOV3 } from '@/utils/api/services/openapi';
import { LoggingContextType } from '@/utils/logger';
import { ChargingSupplier } from './constants/ChargingSupplier';
import { SubscriptionStatus } from './constants/SubscriptionStatus';
import { StringOptionType } from '@/components/InputSelect';
import { format } from 'date-fns';
import parseISO from 'date-fns/parseISO';
import { TranslationFunction } from '@/utils/translation/useTranslation';

const customTwMerge = extendTailwindMerge({
  classGroups: {
    'font-weight': [
      {
        font: ['light', 'regular', 'medium', 'heavy']
      }
    ],
    'font-size': [
      {
        text: [
          '1300-d',
          '1200-d',
          '1100-d',
          '1000-d',
          '900-d',
          '800-d',
          '700-d',
          '600-d',
          '500-d',
          '400-d',
          '300-d',
          '200-d',
          '100-d',
          '75-d',
          '50-d',
          '1300-m',
          '1200-m',
          '1100-m',
          '1000-m',
          '900-m',
          '800-m',
          '700-m',
          '600-m',
          '500-m',
          '400-m',
          '300-m',
          '200-m',
          '100-m',
          '75-m',
          '50-m'
        ]
      }
    ]
  }
});

export function cn(...inputs: ClassValue[]) {
  return customTwMerge(clsx(inputs));
}

const isReestablishmentProduct = (productCode?: string | null): boolean =>
  process.env.NEXT_PUBLIC_SF_PRODUCT_CODE_CAMPAIGN_REESTABLISHMENT.split(';').includes(productCode ?? '');

const isNowProduct = (productCode?: string | null): boolean =>
  process.env.NEXT_PUBLIC_SF_PRODUCT_CODE_CAMPAIGN_NOW.split(';').includes(productCode ?? '');

const isReadyProduct = (productCode?: string | null): boolean =>
  process.env.NEXT_PUBLIC_SF_PRODUCT_CODE_CAMPAIGN_READY.split(';').includes(productCode ?? '');

const isOwnedProduct = (campaignId?: string | null): boolean =>
  process.env.NEXT_PUBLIC_SF_PRODUCT_CODE_CAMPAIGN_OWNED.split(';').includes(campaignId ?? '');

export function campaignIdToEstablishmentType(productCode?: string | null, logger?: LoggingContextType) {
  if (isReestablishmentProduct(productCode)) {
    return EstablishmentTypeEnum._3;
  }
  if (isNowProduct(productCode)) {
    return EstablishmentTypeEnum._1;
  }
  if (isReadyProduct(productCode)) {
    return EstablishmentTypeEnum._0;
  }
  if (isOwnedProduct(productCode)) {
    return EstablishmentTypeEnum._4;
  }
  if (productCode != undefined) {
    logger?.error('Tried to get establishment type from Product Code ${ProductCode} that isnt mapped', productCode);
  } else {
    logger?.warn('Tried to get establishment type from undefined');
  }
  return undefined;
}

export const isChargingSupplierWithBackplate = (supplier: ChargingSupplier | string | null | undefined) =>
  supplier === ChargingSupplier.Easee || supplier === ChargingSupplier.Garo;

export function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function lowercaseFirstLetter(string: string) {
  return string.charAt(0).toLowerCase() + string.slice(1);
}

export function getSubscriptionStatusText(t: TranslationFunction, status?: string | null) {
  switch (status) {
    case SubscriptionStatus.Draft: {
      return t('global:subscriptionStatus.draft');
    }
    case SubscriptionStatus.New: {
      return t('global:subscriptionStatus.new');
    }
    case SubscriptionStatus.Active: {
      return t('global:subscriptionStatus.active');
    }
    case SubscriptionStatus.OnHold: {
      return t('global:subscriptionStatus.onHold');
    }
    case SubscriptionStatus.Frozen: {
      return t('global:subscriptionStatus.frozen');
    }
    case SubscriptionStatus.Suspended: {
      return t('global:subscriptionStatus.suspended');
    }
    case SubscriptionStatus.Terminated: {
      return t('global:subscriptionStatus.terminated');
    }
    default: {
      return t('global:subscriptionStatus.unknown');
    }
  }
}

export const subscriptionsToOptions = (
  t: TranslationFunction,
  subscriptions: Array<SubscriptionDTOV3>
): Array<StringOptionType> => {
  const kwhCounts: {
    [id: number]: number;
  } = {};

  subscriptions.forEach(subscription => {
    kwhCounts[subscription?.recurringPricing?.kwh ?? 0] =
      (kwhCounts[subscription?.recurringPricing?.kwh ?? 0] ?? 0) + 1;
  });

  return subscriptions.map(({ recurringPricing, parkingLot, orderDate, status, id }) => {
    if (!recurringPricing) {
      return { label: `n/a (${getSubscriptionStatusText(t, status)})`, value: id! };
    }

    const kwh = recurringPricing?.kwh;

    const name = !kwh
      ? t('global:subscriptionPackageName.withoutKwh')
      : kwhCounts[kwh] === 1
      ? t('global:subscriptionPackageName.withKwh', { kwh })
      : `${t('global:subscriptionPackageName.withKwh', { kwh })} (${
          parkingLot?.name != null
            ? parkingLot.name
            : orderDate
            ? format(parseISO(orderDate), t('global:dateFormats.fullNumbered'))
            : ''
        })`;

    return { label: name, value: id! };
  });
};

export enum PaymentStatus {
  Paid = 'Paid',
  Unpaid = 'Unpaid',
  MissingPayment = 'Missing Payment',
  Failed = 'Failed'
}

export function getPaymentStatusText(t: TranslationFunction, status?: string | PaymentStatus | null) {
  switch (status) {
    case PaymentStatus.Paid: {
      return t('global:paymentStatus.paid');
    }
    case PaymentStatus.Unpaid: {
      return t('global:paymentStatus.unpaid');
    }
    case PaymentStatus.MissingPayment: {
      return t('global:paymentStatus.missingPayment');
    }
    case PaymentStatus.Failed: {
      return t('global:paymentStatus.failed');
    }
    default: {
      return t('global:paymentStatus.default');
    }
  }
}
