import { UserRole } from './constants/UserRole';
import jwt_decode from 'jwt-decode';
import { UserToken } from '@/state/user';
import qs from 'querystring';
import { IncomingMessage } from 'http';

const roleIsAdministrator = (role: string): boolean => role === UserRole.Administrator;

const roleIsCustomerService = (role: string): boolean => role === UserRole.CustomerService;

const roleIsAdministratorOrCustomerService = (role: string): boolean =>
  roleIsAdministrator(role) || roleIsCustomerService(role);

export const isAdministrator = (role?: null | string | string[]): boolean =>
  role == null ? false : typeof role === 'string' ? roleIsAdministrator(role) : role.some(roleIsAdministrator);

export const isAdministratorOrCustomerService = (role?: null | string | string[]): boolean =>
  role == null
    ? false
    : typeof role === 'string'
    ? roleIsAdministratorOrCustomerService(role)
    : role.some(roleIsAdministratorOrCustomerService);

const roleIsCustomer = (role: string): boolean => role === UserRole.Customer;

export const isCustomer = (role?: null | string | string[]): boolean =>
  role == null ? false : typeof role === 'string' ? roleIsCustomer(role) : role.some(roleIsCustomer);

export const getServerSideUser = (req?: IncomingMessage) => {
  const cookies = qs.decode(req?.headers.cookie ?? '', '; ');
  const access_token = cookies['access_token'];
  const refresh_token_expires_at = cookies['refresh_token_expires_at'];
  let user = null;
  try {
    if (
      access_token &&
      typeof access_token == 'string' &&
      refresh_token_expires_at &&
      typeof refresh_token_expires_at == 'string'
    ) {
      user = jwt_decode<UserToken>(access_token);
      user.refreshExp = refresh_token_expires_at;
    }
  } catch {}
  return user;
};
