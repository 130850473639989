import { cn } from '@/utils/utils';
import React from 'react';
import AlertIcon from '@/public/assets/icons/alert.svg';
import EnergyIcon from '@/public/assets/icons/energy.svg';

interface Props {
  type?: BannerType;
  title: React.ReactNode;
  body: React.ReactNode;
}

export enum BannerType {
  Alert = 'Alert',
  Warning = 'Warning',
  Info = 'Info',
  Success = 'Success'
}

const getBannerIcon = (type?: BannerType) => {
  switch (type) {
    case BannerType.Alert: {
      return <AlertIcon height="16" />;
    }
    default: {
      return <EnergyIcon height="16" />;
    }
  }
};

export const Banner: React.FC<Props> = ({ type = BannerType.Alert, title, body }) => {
  return (
    <div
      className={cn('min-h-12', {
        'bg-status-negative-dimmed': type == BannerType.Alert,
        'bg-palette-yellow-200': type == BannerType.Warning,
        'bg-status-informative-dimmed': type == BannerType.Info,
        'bg-status-positive-dimmed': type == BannerType.Success
      })}
    >
      <div
        className={cn(
          'grid',
          'grid-cols-[min-content,auto]',
          'grid-rows-[min-content,auto]',
          'items-center',
          'gap-x-small',
          'gap-y-extra-small',
          'py-medium',
          'md:p-medium',
          'max-w-md',
          'mx-auto'
        )}
      >
        <div>{getBannerIcon(type)}</div>
        {title}
        <div className={cn('col-start-2')}>{body}</div>
      </div>
    </div>
  );
};
