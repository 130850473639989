import React from 'react';
import { Link } from '@/utils/translation/navigation';
import { cn } from '@/utils/utils';
import ChargingCable from '@/public/assets/icons/chargingCable.svg';
import Consumption from '@/public/assets/icons/consumption.svg';
import Receipt from '@/public/assets/icons/receipt.svg';
import User from '@/public/assets/icons/user.svg';
import Logout from '@/public/assets/icons/logout.svg';
import { Typography } from '@/components/Typography';
import { useTranslation } from '@/utils/translation/useTranslation';
import Support from '@/public/assets/icons/support.svg';
import { usePathname } from '@/utils/hooks/usePathname';

interface NavigationEntryBase {
  text: string;
  icon: React.ReactNode;
  dataCy?: string;
}
interface NavigationEntryInternal extends NavigationEntryBase {
  path: string;
  href?: never;
}
interface NavigationEntryExternal extends NavigationEntryBase {
  path?: never;
  href: string;
}
export type NavigationEntries = Array<NavigationEntryInternal | NavigationEntryExternal>;

export const DesktopHeaderMenu: React.FC = () => {
  const { t } = useTranslation();
  const pathname = usePathname({ includeLocale: false });

  const navigationEntries: NavigationEntries = [
    {
      text: t('global:navigation.charger'),
      path: '/my-page/charger',
      dataCy: 'chargerLinkIcon',
      icon: <ChargingCable className={cn('w-[32px]', 'shrink-0', '[&>path]:fill-gray-100')} />
    },
    {
      text: t('global:navigation.consumption'),
      path: '/my-page/consumption',
      dataCy: 'consumptionLinkIcon',
      icon: <Consumption className={cn('w-[32px]', 'shrink-0', '[&>path]:fill-gray-100')} />
    },
    {
      text: t('global:navigation.receipt'),
      path: '/my-page/receipts',
      dataCy: 'receiptLinkIcon',
      icon: <Receipt className={cn('w-[32px]', 'shrink-0', '[&>path]:fill-gray-100')} />
    },
    {
      text: t('global:navigation.profile'),
      path: '/my-page/profile',
      dataCy: 'profileLinkIcon',
      icon: <User className={cn('w-[32px]', 'shrink-0', '[&>path]:fill-gray-100')} />
    },
    {
      text: t('global:navigation.contact'),
      href: t('global:links.support'),
      dataCy: 'contactLinkIcon',
      icon: <Support className={cn('w-[32px]', 'shrink-0', '[&>path]:fill-gray-100')} />
    },
    {
      text: t('global:navigation.logout'),
      path: '/auth/logout',
      dataCy: 'logoutLinkIcon',
      icon: <Logout className={cn('w-[32px]', 'shrink-0', '[&>path]:fill-gray-100')} />
    }
  ];

  return (
    <nav>
      <ul className={cn('flex', 'gap-8', 'md:gap-6', 'text-gray-100')}>
        {navigationEntries.map(entry => {
          const isPath = pathname === entry.path;
          return (
            <li key={entry.path ?? entry.href}>
              <Typography
                data-cy={entry.dataCy}
                type="S-H"
                component={entry.path ? Link : 'a'}
                href={entry.path ?? entry.href}
                className={cn(
                  'flex',
                  'gap-1',
                  'items-center',
                  'px-3',
                  'py-1',
                  'rounded-lg',
                  'hover:text-common-black',
                  '[&>svg>path]:hover:fill-common-black',
                  {
                    'text-common-black': isPath,
                    'bg-blue-50': isPath,
                    'hover:bg-gray-40': !isPath,
                    '[&>svg>path]:fill-common-black': isPath
                  },
                  'whitespace-nowrap'
                )}
              >
                {entry.icon}
                <Typography component="span" className={cn({ 'xl:hidden': !isPath })}>
                  {entry.text}
                </Typography>
              </Typography>
            </li>
          );
        })}
      </ul>
    </nav>
  );
};
