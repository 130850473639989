import { ReactNode } from 'react';
import PiwikProProvider from '@piwikpro/next-piwik-pro';

export const PiwikProvider = ({ children }: { children: ReactNode }) => {
  const piwikUrl = process.env.NEXT_PUBLIC_PIWIK_URL;
  const piwik = process.env.NEXT_PUBLIC_PIWIK_ID;

  if (piwikUrl === undefined || piwik === undefined) {
    return <>{children}</>;
  }

  return (
    <PiwikProProvider containerUrl={piwikUrl} containerId={piwik}>
      {children}
    </PiwikProProvider>
  );
};
