const SplashScreensIOS = [
  {
    width: 430,
    height: 932,
    ratio: 3,
    orientation: 'landscape',
    name: 'iPhone_15_Pro_Max__iPhone_15_Plus__iPhone_14_Pro_Max_landscape.png'
  },
  {
    width: 393,
    height: 852,
    ratio: 3,
    orientation: 'landscape',
    name: 'iPhone_15_Pro__iPhone_15__iPhone_14_Pro_landscape.png'
  },
  {
    width: 428,
    height: 926,
    ratio: 3,
    orientation: 'landscape',
    name: 'iPhone_14_Plus__iPhone_13_Pro_Max__iPhone_12_Pro_Max_landscape.png'
  },
  {
    width: 390,
    height: 844,
    ratio: 3,
    orientation: 'landscape',
    name: 'iPhone_14__iPhone_13_Pro__iPhone_13__iPhone_12_Pro__iPhone_12_landscape.png'
  },
  {
    width: 375,
    height: 812,
    ratio: 3,
    orientation: 'landscape',
    name: 'iPhone_13_mini__iPhone_12_mini__iPhone_11_Pro__iPhone_XS__iPhone_X_landscape.png'
  },
  {
    width: 414,
    height: 896,
    ratio: 3,
    orientation: 'landscape',
    name: 'iPhone_11_Pro_Max__iPhone_XS_Max_landscape.png'
  },
  {
    width: 414,
    height: 896,
    ratio: 2,
    orientation: 'landscape',
    name: 'iPhone_11__iPhone_XR_landscape.png'
  },
  {
    width: 414,
    height: 736,
    ratio: 3,
    orientation: 'landscape',
    name: 'iPhone_8_Plus__iPhone_7_Plus__iPhone_6s_Plus__iPhone_6_Plus_landscape.png'
  },
  {
    width: 375,
    height: 667,
    ratio: 2,
    orientation: 'landscape',
    name: 'iPhone_8__iPhone_7__iPhone_6s__iPhone_6__4.7__iPhone_SE_landscape.png'
  },
  {
    width: 320,
    height: 568,
    ratio: 2,
    orientation: 'landscape',
    name: '4__iPhone_SE__iPod_touch_5th_generation_and_later_landscape.png'
  },
  {
    width: 1024,
    height: 1366,
    ratio: 2,
    orientation: 'landscape',
    name: '12.9__iPad_Pro_landscape.png'
  },
  {
    width: 834,
    height: 1194,
    ratio: 2,
    orientation: 'landscape',
    name: '11__iPad_Pro__10.5__iPad_Pro_landscape.png'
  },
  {
    width: 820,
    height: 1180,
    ratio: 2,
    orientation: 'landscape',
    name: '10.9__iPad_Air_landscape.png'
  },
  {
    width: 834,
    height: 1112,
    ratio: 2,
    orientation: 'landscape',
    name: '10.5__iPad_Air_landscape.png'
  },
  {
    width: 810,
    height: 1080,
    ratio: 2,
    orientation: 'landscape',
    name: '10.2__iPad_landscape.png'
  },
  {
    width: 768,
    height: 1024,
    ratio: 2,
    orientation: 'landscape',
    name: '9.7__iPad_Pro__7.9__iPad_mini__9.7__iPad_Air__9.7__iPad_landscape.png'
  },
  {
    width: 744,
    height: 1133,
    ratio: 2,
    orientation: 'landscape',
    name: '8.3__iPad_Mini_landscape.png'
  },
  {
    width: 430,
    height: 932,
    ratio: 3,
    orientation: 'portrait',
    name: 'iPhone_15_Pro_Max__iPhone_15_Plus__iPhone_14_Pro_Max_portrait.png'
  },
  {
    width: 393,
    height: 852,
    ratio: 3,
    orientation: 'portrait',
    name: 'iPhone_15_Pro__iPhone_15__iPhone_14_Pro_portrait.png'
  },
  {
    width: 428,
    height: 926,
    ratio: 3,
    orientation: 'portrait',
    name: 'iPhone_14_Plus__iPhone_13_Pro_Max__iPhone_12_Pro_Max_portrait.png'
  },
  {
    width: 390,
    height: 844,
    ratio: 3,
    orientation: 'portrait',
    name: 'iPhone_14__iPhone_13_Pro__iPhone_13__iPhone_12_Pro__iPhone_12_portrait.png'
  },
  {
    width: 375,
    height: 812,
    ratio: 3,
    orientation: 'portrait',
    name: 'iPhone_13_mini__iPhone_12_mini__iPhone_11_Pro__iPhone_XS__iPhone_X_portrait.png'
  },
  {
    width: 414,
    height: 896,
    ratio: 3,
    orientation: 'portrait',
    name: 'iPhone_11_Pro_Max__iPhone_XS_Max_portrait.png'
  },
  {
    width: 414,
    height: 896,
    ratio: 2,
    orientation: 'portrait',
    name: 'iPhone_11__iPhone_XR_portrait.png'
  },
  {
    width: 414,
    height: 736,
    ratio: 3,
    orientation: 'portrait',
    name: 'iPhone_8_Plus__iPhone_7_Plus__iPhone_6s_Plus__iPhone_6_Plus_portrait.png'
  },
  {
    width: 375,
    height: 667,
    ratio: 2,
    orientation: 'portrait',
    name: 'iPhone_8__iPhone_7__iPhone_6s__iPhone_6__4.7__iPhone_SE_portrait.png'
  },
  {
    width: 320,
    height: 568,
    ratio: 2,
    orientation: 'portrait',
    name: '4__iPhone_SE__iPod_touch_5th_generation_and_later_portrait.png'
  },
  {
    width: 1024,
    height: 1366,
    ratio: 2,
    orientation: 'portrait',
    name: '12.9__iPad_Pro_portrait.png'
  },
  {
    width: 834,
    height: 1194,
    ratio: 2,
    orientation: 'portrait',
    name: '11__iPad_Pro__10.5__iPad_Pro_portrait.png'
  },
  {
    width: 820,
    height: 1180,
    ratio: 2,
    orientation: 'portrait',
    name: '10.9__iPad_Air_portrait.png'
  },
  {
    width: 834,
    height: 1112,
    ratio: 2,
    orientation: 'portrait',
    name: '10.5__iPad_Air_portrait.png'
  },
  {
    width: 810,
    height: 1080,
    ratio: 2,
    orientation: 'portrait',
    name: '10.2__iPad_portrait.png'
  },
  {
    width: 768,
    height: 1024,
    ratio: 2,
    orientation: 'portrait',
    name: '9.7__iPad_Pro__7.9__iPad_mini__9.7__iPad_Air__9.7__iPad_portrait.png'
  },
  {
    width: 744,
    height: 1133,
    ratio: 2,
    orientation: 'portrait',
    name: '8.3__iPad_Mini_portrait.png'
  }
];

export default SplashScreensIOS;
