/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { HubVersionInfoStringRuntimeInfo_f__AnonymousType1 } from '../models/HubVersionInfoStringRuntimeInfo_f__AnonymousType1';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class EHubApiService {

    /**
     * @returns HubVersionInfoStringRuntimeInfo_f__AnonymousType1 Success
     * @throws ApiError
     */
    public static httpGetHealth(): CancelablePromise<HubVersionInfoStringRuntimeInfo_f__AnonymousType1> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/health',
        });
    }

}
