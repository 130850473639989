/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PromotionDTO } from '../models/PromotionDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class PromotionService {

    /**
     * @param facilityId
     * @param discountCode
     * @returns PromotionDTO Success
     * @throws ApiError
     */
    public static getAllFacilityPromotions(
        facilityId: string,
        discountCode?: string,
    ): CancelablePromise<Array<PromotionDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/promotion/facility/{facilityId}',
            path: {
                'facilityId': facilityId,
            },
            query: {
                'discountCode': discountCode,
            },
        });
    }

    /**
     * @param discountCode
     * @param facilityId
     * @returns PromotionDTO Success
     * @throws ApiError
     */
    public static getPromotionByDiscountCode(
        discountCode: string,
        facilityId?: string,
    ): CancelablePromise<PromotionDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/promotion/discount-code/{discountCode}',
            path: {
                'discountCode': discountCode,
            },
            query: {
                'facilityId': facilityId,
            },
        });
    }

}
