/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CarConsumptionDto } from '../models/CarConsumptionDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CarConsumptionService {

    /**
     * @param make
     * @param model
     * @param year
     * @returns CarConsumptionDto Success
     * @throws ApiError
     */
    public static get(
        make?: string,
        model?: string,
        year?: number,
    ): CancelablePromise<Array<CarConsumptionDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/carconsumption',
            query: {
                'make': make,
                'model': model,
                'year': year,
            },
        });
    }

    /**
     * @returns string Success
     * @throws ApiError
     */
    public static getMakes(): CancelablePromise<Array<string>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/carconsumption/makes',
        });
    }

    /**
     * @param make
     * @returns string Success
     * @throws ApiError
     */
    public static getModels(
        make?: string,
    ): CancelablePromise<Array<string>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/carconsumption/models',
            query: {
                'make': make,
            },
        });
    }

    /**
     * @param formData
     * @returns CarConsumptionDto Success
     * @throws ApiError
     */
    public static uploadExcelData(
        formData?: {
            file?: Blob;
        },
    ): CancelablePromise<Array<CarConsumptionDto>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/carconsumption/excel',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

}
