/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum TerminatedReason {
    MOVED = 'Moved',
    CHANGED_CAR = 'ChangedCar',
    NOT_HAPPY_WITH_SERVICE = 'NotHappyWithService',
    DECEASED = 'Deceased',
    OTHER = 'Other',
}
