import { QueryKey, QueryObserverResult, useQuery, UseQueryOptions } from '@tanstack/react-query';
import { AnnouncementDTO, AnnouncementService } from '@/utils/api/services/openapi';
import { useAtomValue } from 'jotai';
import { userAtom } from '@/state/user';

const announcementsDefaultQueryKey = 'getAnnouncements';

const fetcher = async () => {
  return AnnouncementService.getAnnouncements();
};

const getQueryKey = (userId?: string) => {
  return [announcementsDefaultQueryKey, userId];
};

const useAnnouncements = (
  options: UseQueryOptions<AnnouncementDTO[], unknown, AnnouncementDTO[], QueryKey> = {}
): QueryObserverResult<AnnouncementDTO[]> => {
  const user = useAtomValue(userAtom);
  const userId = user?.externalId;

  const query = useQuery<AnnouncementDTO[]>({
    queryKey: getQueryKey(userId),
    queryFn: () => fetcher(),
    ...options
  });

  return { ...query, isLoading: query.isLoading && options.enabled !== false } as QueryObserverResult<
    AnnouncementDTO[]
  >;
};

export { useAnnouncements, getQueryKey };
