/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ParkingLotDTO } from '../models/ParkingLotDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ParkingLotService {

    /**
     * @param facilityId
     * @returns ParkingLotDTO Success
     * @throws ApiError
     */
    public static getParkingLotsByFacilityId(
        facilityId: string,
    ): CancelablePromise<Array<ParkingLotDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/parkinglot/charging-facility/{facilityId}',
            path: {
                'facilityId': facilityId,
            },
        });
    }

    /**
     * @param id
     * @returns ParkingLotDTO Success
     * @throws ApiError
     */
    public static getParkingLotById(
        id: string,
    ): CancelablePromise<ParkingLotDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/parkinglot/{id}',
            path: {
                'id': id,
            },
        });
    }

}
