/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AddAnnouncement } from '../models/AddAnnouncement';
import type { AnnouncementDTO } from '../models/AnnouncementDTO';
import type { DeleteAnnouncement } from '../models/DeleteAnnouncement';
import type { EditAnnouncement } from '../models/EditAnnouncement';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class AnnouncementService {

    /**
     * @returns AnnouncementDTO Success
     * @throws ApiError
     */
    public static getAnnouncements(): CancelablePromise<Array<AnnouncementDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/announcement',
        });
    }

    /**
     * @returns AnnouncementDTO Success
     * @throws ApiError
     */
    public static getAllAnnouncements(): CancelablePromise<Array<AnnouncementDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/announcement/all',
        });
    }

    /**
     * @param requestBody
     * @returns any Success
     * @throws ApiError
     */
    public static addAnnouncement(
        requestBody?: AddAnnouncement,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/announcement/add',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns any Success
     * @throws ApiError
     */
    public static editAnnouncement(
        requestBody?: EditAnnouncement,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/announcement/edit',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns any Success
     * @throws ApiError
     */
    public static deleteAnnouncement(
        requestBody?: DeleteAnnouncement,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/announcement/delete',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
