'use client';

import React from 'react';
import { useAtomValue } from 'jotai';
import { userAtom } from '@/state/user';
import { useAnnouncements } from '@/utils/hooks/useAnnouncements';
import { Banner, BannerType } from '@/components/Banner';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Typography } from '@/components/Typography';
import { AnnouncementType } from '@/utils/api/services/openapi';
import Markdown from 'react-markdown';
import { MarkdownLink } from '@/components/MarkdownLink';
import { useLocale } from 'next-intl';

const AnnouncementMarkdown: React.FC<{ children?: string | null; isTitle?: boolean }> = ({ children, isTitle }) => {
  return (
    <Markdown
      components={{
        a(props) {
          return <MarkdownLink href={props.href ?? '#'} text={props.children as React.ReactNode} />;
        },
        p(props) {
          return (
            <Typography
              type={isTitle ? 'S-B' : 'XS-B'}
              component="span"
              className={isTitle ? 'font-regular' : 'font-light'}
            >
              {props.children as React.ReactNode}
            </Typography>
          );
        },
        em(props) {
          return (
            <Typography component="span" style="italic-reverse">
              {props.children as React.ReactNode}
            </Typography>
          );
        }
      }}
    >
      {children}
    </Markdown>
  );
};

const Announcements: React.FC = () => {
  const user = useAtomValue(userAtom);
  const locale = useLocale();
  const { data: announcements } = useAnnouncements({
    enabled: user !== undefined
  });

  if (announcements == undefined || announcements.length === 0) {
    return null;
  }

  const getBannerType = (type?: AnnouncementType) => {
    switch (type) {
      case AnnouncementType._0: {
        return BannerType.Alert;
      }
      case AnnouncementType._1: {
        return BannerType.Warning;
      }
      case AnnouncementType._2: {
        return BannerType.Info;
      }
      case AnnouncementType._3: {
        return BannerType.Success;
      }
      default: {
        return BannerType.Alert;
      }
    }
  };

  return (
    <>
      {announcements?.map(a => (
        <Banner
          key={a.id}
          type={getBannerType(a.type)}
          title={
            <AnnouncementMarkdown isTitle>
              {a.announcementTexts?.find(text => text.locale === locale)?.title}
            </AnnouncementMarkdown>
          }
          body={
            <AnnouncementMarkdown>
              {a.announcementTexts?.find(text => text.locale === locale)?.body}
            </AnnouncementMarkdown>
          }
        />
      ))}
    </>
  );
};

const QueryClientWrappedAnnouncements: React.FC<{ queryClient: QueryClient }> = ({ queryClient }) => {
  return (
    <QueryClientProvider client={queryClient}>
      <Announcements />
    </QueryClientProvider>
  );
};

export { QueryClientWrappedAnnouncements as Announcements };
