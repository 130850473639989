/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Country } from '../models/Country';
import type { ExternalAccountResponse } from '../models/ExternalAccountResponse';
import type { GeoLocationBody } from '../models/GeoLocationBody';
import type { HousingCompanyDto } from '../models/HousingCompanyDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class HousingCompanyService {

    /**
     * @param country
     * @param nameToSearch
     * @returns ExternalAccountResponse Success
     * @throws ApiError
     */
    public static searchHousingCompaniesByName(
        country: Country,
        nameToSearch?: string,
    ): CancelablePromise<Array<ExternalAccountResponse>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/housingcompany/search',
            query: {
                'nameToSearch': nameToSearch,
                'country': country,
            },
        });
    }

    /**
     * @param name
     * @param country
     * @returns HousingCompanyDto Success
     * @throws ApiError
     */
    public static searchHousingCompaniesByNameV2(
        name: string,
        country: Country,
    ): CancelablePromise<Array<HousingCompanyDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/housingcompany/v2/search',
            query: {
                'name': name,
                'country': country,
            },
        });
    }

    /**
     * @param requestBody
     * @returns ExternalAccountResponse Success
     * @throws ApiError
     */
    public static searchHousingCompaniesByLocation(
        requestBody: GeoLocationBody,
    ): CancelablePromise<Array<ExternalAccountResponse>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/housingcompany/location/search',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param contractId
     * @returns ExternalAccountResponse Success
     * @throws ApiError
     */
    public static getHousingCompanyByContractId(
        contractId: string,
    ): CancelablePromise<ExternalAccountResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/housingcompany/charging-facility/{contractId}/housing-company',
            path: {
                'contractId': contractId,
            },
        });
    }

    /**
     * @param id
     * @returns ExternalAccountResponse Success
     * @throws ApiError
     */
    public static getHousingCompanyById(
        id: string,
    ): CancelablePromise<ExternalAccountResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/housingcompany/housing-company/{id}',
            path: {
                'id': id,
            },
        });
    }

}
