/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CarDTO } from '../models/CarDTO';
import type { CarModelDTO } from '../models/CarModelDTO';
import type { CarWarningDTO } from '../models/CarWarningDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CarService {

    /**
     * @param id
     * @returns CarDTO Success
     * @throws ApiError
     */
    public static getCarById(
        id: string,
    ): CancelablePromise<CarDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/car/car/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param subscriptionId
     * @returns CarDTO Success
     * @throws ApiError
     */
    public static getCarBySubscriptionId(
        subscriptionId: string,
    ): CancelablePromise<CarDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/car/subscription/{subscriptionId}/car',
            path: {
                'subscriptionId': subscriptionId,
            },
        });
    }

    /**
     * @param brand
     * @param model
     * @returns CarWarningDTO Success
     * @throws ApiError
     */
    public static getCarSelectionWarning(
        brand?: string,
        model?: string,
    ): CancelablePromise<CarWarningDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/car/special-car-warning',
            query: {
                'Brand': brand,
                'Model': model,
            },
        });
    }

    /**
     * @returns CarModelDTO Success
     * @throws ApiError
     */
    public static getCarModelsWithWarning(): CancelablePromise<Array<CarModelDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/car/models-with-warning',
        });
    }

}
