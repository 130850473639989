export enum ChargingSupplier {
  Easee = 'Easee',
  Ensto = 'Ensto',
  Ensto2 = 'Chago',
  Defa = 'Defa',
  Garo = 'Garo',
  Schneider = 'Schneider',
  Schneider2 = 'Schneider Electric',
  Zaptec = 'Zaptec'
}
