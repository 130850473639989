import { useTranslations } from 'next-intl';
import { getTranslations } from 'next-intl/server';
import type { TranslationValues, MarkupTranslationValues, RichTranslationValues } from 'next-intl';

const useTranslation = () => {
  const t = useTranslations();

  const customT = (key: string, interpolation?: TranslationValues) => {
    const newKey = key.replace(':', '.');
    return t(newKey, interpolation);
  };

  customT.rich = (key: string, interpolation?: RichTranslationValues) => {
    const newKey = key.replace(':', '.');
    return t.rich(newKey, interpolation);
  };

  customT.markup = (key: string, interpolation?: MarkupTranslationValues) => {
    const newKey = key.replace(':', '.');
    return t.markup(newKey, interpolation);
  };

  return {
    t: customT
  };
};

const getTranslation = async () => {
  const t = await getTranslations();
  const customT = (key: string, interpolation?: TranslationValues) => {
    const newKey = key.replace(':', '.');
    return t(newKey, interpolation);
  };

  customT.rich = (key: string, interpolation?: RichTranslationValues) => {
    const newKey = key.replace(':', '.');
    return t.rich(newKey, interpolation);
  };

  customT.markup = (key: string, interpolation?: MarkupTranslationValues) => {
    const newKey = key.replace(':', '.');
    return t.markup(newKey, interpolation);
  };

  return {
    t: customT
  };
};

type TranslationFunction = ReturnType<typeof useTranslation>['t'];

export { useTranslation, getTranslation };
export type { TranslationFunction };
