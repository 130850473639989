import { useSearchParams as useNextSearchParams } from 'next/navigation';

const useSearchParams: (options?: { includeLocale: boolean }) => Readonly<URLSearchParams> | null = (
  options = { includeLocale: true }
) => {
  let searchParams = useNextSearchParams();

  if (searchParams === null) {
    return null;
  }

  const newSearchParams = new URLSearchParams(searchParams);

  if (!options.includeLocale) {
    newSearchParams.delete('locale');
  }

  return Object.freeze(newSearchParams);
};

export { useSearchParams };
