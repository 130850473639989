export enum ChargerStatus {
  Available = 'Available',
  Charging = 'Charging',
  Error = 'Error',
  Finishing = 'Finishing',
  Offline = 'Offline',
  Preparing = 'Preparing',
  SuspendedCar = 'SuspendedCAR',
  SuspendedCharger = 'SuspendedCHARGER',
  Unavailable = 'Unavailable'
}
