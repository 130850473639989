/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AddNotificationCommand } from '../models/AddNotificationCommand';
import type { AddNotificationTypeCommand } from '../models/AddNotificationTypeCommand';
import type { NotificationResponse } from '../models/NotificationResponse';
import type { NotificationTypeResponse } from '../models/NotificationTypeResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class NotificationService {

    /**
     * @param id
     * @returns boolean Success
     * @throws ApiError
     */
    public static markNotificationAsSeen(
        id: number,
    ): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/notification/{id}/seen',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param requestBody
     * @returns NotificationResponse Success
     * @throws ApiError
     */
    public static addNotification(
        requestBody?: AddNotificationCommand,
    ): CancelablePromise<NotificationResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/notification/add',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @param requestBody
     * @returns NotificationTypeResponse Success
     * @throws ApiError
     */
    public static addNotificationType(
        requestBody?: AddNotificationTypeCommand,
    ): CancelablePromise<NotificationTypeResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/notification/add-type',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @param id
     * @returns NotificationResponse Success
     * @throws ApiError
     */
    public static getNotification(
        id: number,
    ): CancelablePromise<NotificationResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/notification/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id
     * @returns boolean Success
     * @throws ApiError
     */
    public static deleteNotification(
        id: number,
    ): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/notification/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param userId
     * @returns NotificationResponse Success
     * @throws ApiError
     */
    public static getCustomerNotifications(
        userId: string,
    ): CancelablePromise<Array<NotificationResponse>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/notification/customer/{userId}',
            path: {
                'userId': userId,
            },
        });
    }

    /**
     * @param userId
     * @returns number Success
     * @throws ApiError
     */
    public static getUnreadCustomerNotificationsCount(
        userId: string,
    ): CancelablePromise<number> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/notification/user/{userId}/unread-count',
            path: {
                'userId': userId,
            },
        });
    }

}
