/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelUpcomingLevelRequest } from '../models/CancelUpcomingLevelRequest';
import type { ChangePackageLevelRequest } from '../models/ChangePackageLevelRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class SubscriptionPackageLevelService {

    /**
     * @param requestBody
     * @returns any Success
     * @throws ApiError
     */
    public static upgradeSubscriptionLevel(
        requestBody?: ChangePackageLevelRequest,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/subscriptionpackagelevel/upgrade',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @param requestBody
     * @returns any Success
     * @throws ApiError
     */
    public static downgradeSubscriptionLevel(
        requestBody?: ChangePackageLevelRequest,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/subscriptionpackagelevel/downgrade',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @param requestBody
     * @returns any Success
     * @throws ApiError
     */
    public static cancelUpcomingSubscriptionLevel(
        requestBody?: CancelUpcomingLevelRequest,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/subscriptionpackagelevel',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @param subscriptionId
     * @param amount
     * @returns any Success
     * @throws ApiError
     */
    public static addAdminRollover(
        subscriptionId?: string,
        amount?: number,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/subscriptionpackagelevel/kwh-compensation',
            query: {
                'subscriptionId': subscriptionId,
                'amount': amount,
            },
        });
    }

    /**
     * @param subscriptionId
     * @param amount
     * @returns any Success
     * @throws ApiError
     */
    public static addAdminRollover1(
        subscriptionId?: string,
        amount?: number,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/subscriptionpackagelevel/rollover',
            query: {
                'subscriptionId': subscriptionId,
                'amount': amount,
            },
        });
    }

}
