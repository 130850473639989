/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class DeveloperHelperEndpointsService {

    /**
     * @param count
     * @returns any Success
     * @throws ApiError
     */
    public static httpPostDevPayexCallbackRecentFakeRecentOrders(
        count?: number,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/dev/payex/callback-recent',
            query: {
                'count': count,
            },
        });
    }

    /**
     * @param email
     * @returns any Success
     * @throws ApiError
     */
    public static httpPostDevPayexCallbackViaEmailFakeByEmail(
        email: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/dev/payex/callback-via-email',
            query: {
                'email': email,
            },
        });
    }

    /**
     * @param key
     * @returns any Success
     * @throws ApiError
     */
    public static httpGetDevEmablerMessagesGetByKey(
        key: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/dev/emabler/messages',
            query: {
                'key': key,
            },
        });
    }

    /**
     * @param key
     * @returns any Success
     * @throws ApiError
     */
    public static httpDeleteDevEmablerMessagesEvictByKey(
        key: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/dev/emabler/messages',
            query: {
                'key': key,
            },
        });
    }

}
