/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AddPreOwnedRfidCommand } from '../models/AddPreOwnedRfidCommand';
import type { RemoveRfidFromSfSubscriptionCommand } from '../models/RemoveRfidFromSfSubscriptionCommand';
import type { RfidDTO } from '../models/RfidDTO';
import type { RfidTag } from '../models/RfidTag';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class RfidService {

    /**
     * @param requestBody
     * @returns any Success
     * @throws ApiError
     */
    public static create(
        requestBody?: AddPreOwnedRfidCommand,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/rfid',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @param subscriptionId
     * @returns RfidDTO Success
     * @throws ApiError
     */
    public static getRfiDs(
        subscriptionId?: string,
    ): CancelablePromise<Array<RfidDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/rfid',
            query: {
                'subscriptionId': subscriptionId,
            },
        });
    }

    /**
     * @param requestBody
     * @returns any Success
     * @throws ApiError
     */
    public static deleteRfid(
        requestBody?: RemoveRfidFromSfSubscriptionCommand,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/rfid',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @param subscriptionId
     * @returns RfidTag Success
     * @throws ApiError
     */
    public static getChargerRfiDs(
        subscriptionId?: string,
    ): CancelablePromise<Array<RfidTag>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/rfid/charger',
            query: {
                'subscriptionId': subscriptionId,
            },
        });
    }

}
