/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CompleteOhmiaReadyCommand } from '../models/CompleteOhmiaReadyCommand';
import type { CreateAdditionalOrderCommand } from '../models/CreateAdditionalOrderCommand';
import type { CreateCaseCommand } from '../models/CreateCaseCommand';
import type { CreateOrderCommand } from '../models/CreateOrderCommand';
import type { OrderDTO } from '../models/OrderDTO';
import type { OrderResponse } from '../models/OrderResponse';
import type { SubscriptionDTOV2 } from '../models/SubscriptionDTOV2';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class OrderService {

    /**
     * @param requestBody
     * @returns any Success
     * @throws ApiError
     */
    public static createAdditionalOrder(
        requestBody?: CreateAdditionalOrderCommand,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/order/additional',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @param requestBody
     * @returns OrderResponse Success
     * @throws ApiError
     */
    public static createOrder(
        requestBody?: CreateOrderCommand,
    ): CancelablePromise<OrderResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/order/now',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @param requestBody
     * @returns OrderResponse Success
     * @throws ApiError
     */
    public static completeOrder(
        requestBody?: CompleteOhmiaReadyCommand,
    ): CancelablePromise<OrderResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/order/complete',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @deprecated
     * @returns SubscriptionDTOV2 Success
     * @throws ApiError
     */
    public static getAllOrdersDetailed(): CancelablePromise<Array<SubscriptionDTOV2>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/order/all/detailed',
        });
    }

    /**
     * @param id
     * @returns OrderDTO Success
     * @throws ApiError
     */
    public static getOrderBySubscriptionId(
        id: string,
    ): CancelablePromise<OrderDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/order/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param requestBody
     * @returns any Success
     * @throws ApiError
     */
    public static sendUpdateOrderRequestToCustomerService(
        requestBody?: CreateCaseCommand,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/order/update-order-request',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

}
