import { ParsedUrlQueryInput } from 'node:querystring';
import { useLocalizedRouter } from '@/utils/translation/navigation';
import { locales } from '@/vars';

interface Options {
  scroll?: boolean;
  query?: ParsedUrlQueryInput | URLSearchParams | null;
  shallow?: boolean;
}

const useRouter = () => {
  const { push, replace, ...rest } = useLocalizedRouter();

  const _getUrl = (href: string, options?: Options) => {
    // As we use the useLocalizedRouter router, we need to remove the locale from the pathname if it exists
    // or else we will get something like /nb/nb/...
    let url = href.replace(new RegExp(`^\/(${locales.join('|')})`), '');
    const urlSearchParams = new URLSearchParams();

    if (options?.query) {
      let entries: [string, any][] = Object.entries(options.query);
      if (options.query instanceof URLSearchParams) {
        entries = Array.from(options.query.entries());
      }
      for (const [key, value] of entries) {
        if (value !== undefined && value !== null) {
          urlSearchParams.set(key, value.toString());
        }
      }
    }

    if (urlSearchParams.size > 0) {
      url += `?${urlSearchParams.toString()}`;
    }

    return url;
  };

  const newPush = (href: string, options?: Options) => {
    const url = _getUrl(href, options);
    return options?.shallow ? window.history.pushState(null, '', url) : push(url);
  };

  const newReplace = (href: string, options?: Options) => {
    const url = _getUrl(href, options);
    return options?.shallow ? window.history.replaceState(null, '', url) : replace(url);
  };

  return {
    push: newPush,
    replace: newReplace,
    ...rest
  };
};

export { useRouter };
