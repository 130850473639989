const defaultPage = '/my-page/charger';
const loginPage = '/auth/login';
const logoutPage = '/auth/logout';
const basePath = '/v2';
const locales = ['nb', 'sv'];
const defaultLocale = 'nb';
const localePrefix = 'always';

module.exports = {
  defaultPage,
  loginPage,
  logoutPage,
  basePath,
  locales,
  defaultLocale,
  localePrefix
};
