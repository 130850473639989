/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CustomerDTO } from '../models/CustomerDTO';
import type { CustomerUpdateRequest } from '../models/CustomerUpdateRequest';
import type { ExternalAccountResponsePagedResult } from '../models/ExternalAccountResponsePagedResult';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CustomerService {

    /**
     * @param requestBody
     * @returns CustomerDTO Success
     * @throws ApiError
     */
    public static updateCustomer(
        requestBody?: CustomerUpdateRequest,
    ): CancelablePromise<CustomerDTO> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/customer/update',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @returns CustomerDTO Success
     * @throws ApiError
     */
    public static getCustomer(): CancelablePromise<CustomerDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/customer',
        });
    }

    /**
     * @param includes
     * @param pageSize
     * @param page
     * @param orderByKey
     * @param isDescending
     * @param query
     * @param filters
     * @returns ExternalAccountResponsePagedResult Success
     * @throws ApiError
     */
    public static getPagedExternalAccount(
        includes?: string,
        pageSize?: number,
        page?: number,
        orderByKey?: string,
        isDescending?: boolean,
        query?: string,
        filters?: string,
    ): CancelablePromise<ExternalAccountResponsePagedResult> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/customer/external/paged',
            query: {
                'Includes': includes,
                'PageSize': pageSize,
                'Page': page,
                'OrderByKey': orderByKey,
                'IsDescending': isDescending,
                'Query': query,
                'Filters': filters,
            },
        });
    }

}
