import { toast as toastify, ToastContainer as ToastifyContainer, ToastOptions } from 'react-toastify';
import React from 'react';
import * as Separator from '@radix-ui/react-separator';
import { cn } from '@/utils/utils';
import AlertIcon from '@/public/assets/icons/alert.svg';

interface Props {
  content: React.ReactNode;
}

const Toast: React.FC<Props> = ({ content }) => {
  return (
    <div className={cn('flex', 'justify-between')}>
      <div>{content}</div>
      <Separator.Root className={cn('bg-common-black', 'h-auto', 'w-px', 'ml-3')} decorative orientation="vertical" />
    </div>
  );
};

type ToastType = {
  (content: React.ReactNode, options?: ToastOptions): void;
  info: (content: React.ReactNode, options?: Omit<ToastOptions, 'type'>) => void;
  error: (content: React.ReactNode, options?: Omit<ToastOptions, 'type'>) => void;
  success: (content: React.ReactNode, options?: Omit<ToastOptions, 'type'>) => void;
};

const toast: ToastType = (content, options) => {
  if (options == undefined) {
    options = {};
  }

  if (options?.icon == undefined) {
    options = {
      ...options,
      icon: <AlertIcon className={cn('[&>path]:fill-common-white')} />
    };
  }

  toastify(<Toast content={content} />, options);
};

toast.info = (content, options) => {
  if (options == undefined) {
    options = {};
  }

  const _options: ToastOptions = {
    ...options,
    type: 'info',
    icon: <AlertIcon className={cn('[&>path]:fill-common-white')} />
  };
  toast(content, _options);
};

toast.error = (content, options) => {
  if (options == undefined) {
    options = {};
  }

  const _options: ToastOptions = {
    ...options,
    type: 'error',
    icon: <AlertIcon className={cn('[&>path]:fill-common-white')} />
  };
  toast(content, _options);
};

toast.success = (content, options) => {
  if (options == undefined) {
    options = {};
  }

  const _options: ToastOptions = {
    ...options,
    type: 'success',
    icon: <AlertIcon className={cn('[&>path]:fill-common-black')} />
  };
  toast(content, _options);
};

toast.success = (content, options) => {
  if (options == undefined) {
    options = {};
  }

  const _options: ToastOptions = {
    ...options,
    type: 'success',
    icon: <AlertIcon className={cn('[&>path]:fill-common-black')} />
  };
  toast(content, _options);
};

const ToastContainer: React.FC = () => {
  return (
    <ToastifyContainer
      position="top-right"
      autoClose={20000}
      newestOnTop
      closeOnClick={false}
      rtl={false}
      pauseOnFocusLoss
      draggable
      theme="light"
      toastClassName={props => {
        return cn(
          'relative',
          'min-h-[var(--toastify-toast-min-height)]',
          'box-border',
          'mb-4',
          'flex',
          'justify-between',
          'max-h-[var(--toastify-toast-max-height)]',
          'overflow-hidden',
          {
            'bg-gray-100': props?.type == 'default'
          },
          {
            'bg-blue-100': props?.type == 'info'
          },
          {
            'bg-green-50': props?.type == 'success',
            'text-common-black': props?.type == 'success'
          },
          {
            'bg-system-red-100': props?.type == 'error'
          }
        );
      }}
    />
  );
};

export { toast, ToastContainer };
