/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AuthenticationResponse } from '../models/AuthenticationResponse';
import type { AuthenticationWebauthnRequest } from '../models/AuthenticationWebauthnRequest';
import type { UserAuthenticatorDto } from '../models/UserAuthenticatorDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class WebAuthnService {

    /**
     * @returns any Success
     * @throws ApiError
     */
    public static getUserAuthenticators(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/webauthn/user-authenticator',
        });
    }

    /**
     * @param requestBody
     * @returns any Success
     * @throws ApiError
     */
    public static registerUserAuthenticator(
        requestBody?: UserAuthenticatorDto,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/webauthn/user-authenticator',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param authenticatorId
     * @returns any Success
     * @throws ApiError
     */
    public static getUserAuthenticator(
        authenticatorId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/webauthn/user-authenticator/{authenticatorId}',
            path: {
                'authenticatorId': authenticatorId,
            },
        });
    }

    /**
     * @param authenticatorId
     * @param requestBody
     * @returns any Success
     * @throws ApiError
     */
    public static updateUserAuthenticator(
        authenticatorId: number,
        requestBody?: number,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/webauthn/user-authenticator/{authenticatorId}',
            path: {
                'authenticatorId': authenticatorId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns AuthenticationResponse Success
     * @throws ApiError
     */
    public static authenticate(
        requestBody?: AuthenticationWebauthnRequest,
    ): CancelablePromise<AuthenticationResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/webauthn/authenticate',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
