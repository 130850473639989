import { createSharedPathnamesNavigation } from 'next-intl/navigation';
import { locales, localePrefix } from '@/vars';

export const {
  Link,
  redirect,
  usePathname,
  useRouter: useLocalizedRouter
} = createSharedPathnamesNavigation({
  locales,
  localePrefix
});
