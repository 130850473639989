import { atom } from 'jotai';

export type AuthMethod = 'UsernameAndPassword' | 'Otp';

export interface UserToken {
  baseAccountExternalId?: string;
  baseAccountId?: string;
  email: string;
  exp: number;
  externalId: string;
  preferredSubscriptionId?: string;
  iat: number;
  iss: string;
  name: string;
  nameid: string;
  nbf: number;
  role: string | string[];
  refreshExp: string;
  unique_name: string;
  authmethod: AuthMethod;
}

// UserToken => the parsed token, meaning the user is logged in
// null => no token found, meaning the user is not logged in
// undefined => the atom is uninitialized and still needs to be hydrated
export const userAtom = atom<UserToken | undefined | null>(undefined);
