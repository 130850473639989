import '@aneo-org/design-tokens/tokens.css';
import '@aneo-org/design-react/style.css';
import '@/styles/globals.css';
import '@/components/stepper/desktop/stepper.css';
import '@/components/inputPhone/legacyInputPhone.css';
import '@/components/loader/loader.css';
import 'react-toastify/dist/ReactToastify.css';
import '@/utils/toast/toast.css';
import '@/components/inputDate/inputDate.css';
import '@/components/receipts/ReceiptEntry.css';
import '@/components/header/mobileHeaderMenu/mobileHeaderMenu.css';
import type { AppContext, AppInitialProps, AppProps } from 'next/app';
import NextApp from 'next/app';
import React, { useEffect } from 'react';
import { NextPage } from 'next';
import { OpenAPI } from '@/utils/api/services/openapi';
import { QueryClient } from '@tanstack/react-query';
import { Provider as StateProvider } from 'jotai';
import { ToastContainer } from '@/utils/toast/toast';
import { LoggerProvider } from '@/utils/logger';
import { ErrorBoundary } from '@/components/ErrorBoundary';
import { VERSION } from '@/datadogVersion';
import { useHydrateAtoms } from 'jotai/react/utils';
import { userAtom, UserToken } from '@/state/user';
import { PiwikProvider } from '@/components/PiwikProvider';
import Script from 'next/script';
import TagManager from 'react-gtm-module';
import Head from 'next/head';
import { AuthProvider } from '@/utils/providers/AuthProvider';
import { DefaultGetLayout, GetLayout } from '@/layouts/DefaultLayout';
import { defaultLocale } from '@/vars';
import { Announcements } from '@/components/Announcements';
import { ChargerConnection } from '@/utils/ChargerConnection';
import SplashScreensIOS from '@/utils/constants/SplashScreenMetadataIOS';
import { NextIntlClientProvider, AbstractIntlMessages } from 'next-intl';
import { getServerSideUser } from '@/utils/user';

type Page<P = {}, IP = P> = NextPage<P, IP> & {
  getLayout?: GetLayout;
};

export type { Page };

type MyAppProps<P = {}> = AppProps<P> & {
  Component: Page<P>;
  user?: UserToken;
  pageProps: {
    messages: AbstractIntlMessages;
  };
};

const queryClient = new QueryClient();

const HydrateAtoms: React.FC<{ user?: UserToken }> = ({ user }) => {
  useHydrateAtoms(new Map([[userAtom, user]]));
  return null;
};

const title = 'Aneo Mobility';

function App({ Component, pageProps: { messages, ...pageProps }, user, router }: MyAppProps): JSX.Element {
  const gtmId = process.env.NEXT_PUBLIC_GTM_ID;

  if (process.env.NEXT_PUBLIC_ORIGIN_URL) {
    OpenAPI.BASE = process.env.NEXT_PUBLIC_ORIGIN_URL;
  }

  const getLayout = Component.getLayout ?? DefaultGetLayout;

  useEffect(() => {
    if (gtmId) TagManager.initialize({ gtmId });
  }, [gtmId]);

  // "react-markdown" uses "Object.hasOwn" which isn't supported in 6.4% of browsers starting from around 2021/2022
  if (!Object.hasOwn) {
    // @ts-expect-error: 'object.hasown' is not typed
    const hasOwnPromise = import('object.hasown');
    hasOwnPromise.then(hasOwn => hasOwn.shim());
  }

  return (
    <NextIntlClientProvider
      locale={typeof router.query.locale == 'string' ? router.query.locale : defaultLocale}
      messages={messages}
    >
      <StateProvider>
        <LoggerProvider env={process.env.NEXT_PUBLIC_DATADOG_ENV} version={VERSION}>
          <ErrorBoundary>
            <HydrateAtoms user={user} />
            <AuthProvider queryClient={queryClient}>
              <PiwikProvider>
                <Head>
                  <title>{title}</title>
                  <meta charSet="utf-8" />
                  <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
                  <meta name="application-name" content="Aneo" />
                  <meta name="description" content="" />
                  <meta name="keywords" content="" />
                  <meta name="viewport" content="width=device-width, initial-scale=1" />
                  <meta name="mobile-web-app-capable" content="yes" />
                  <meta name="apple-mobile-web-app-capable" content="yes" />
                  <meta name="apple-mobile-web-app-title" content="Aneo" />
                  <meta name="apple-mobile-web-app-status-bar-style" content="default" />
                  <link rel="icon" href="/favicon/icon.svg" />
                  <link rel="apple-touch-icon" sizes="180x180" href="/favicon/apple-touch-icon.png" />
                  <link rel="apple-touch-icon" sizes="192x192" href="/favicon/icon-192.png" />
                  <link rel="apple-touch-icon" sizes="512x512" href="/favicon/icon-512.png" />
                  <link rel="manifest" href="/favicon/manifest.webmanifest" />

                  <meta name="format-detection" content="telephone=no" />
                  <meta name="msapplication-config" content="/browserconfig.xml" />
                  <meta name="msapplication-TileColor" content="#FFC328" />
                  <meta name="msapplication-tap-highlight" content="no" />
                  <meta name="theme-color" content="#FFC328" />

                  {SplashScreensIOS.map(item => (
                    <link
                      key={item.name}
                      rel="apple-touch-startup-image"
                      media={`screen and (device-width: ${item.width}px) and (device-height: ${item.height}px) and (-webkit-device-pixel-ratio: ${item.ratio}) and (orientation: ${item.orientation})`}
                      href={`/assets/apple-splash-screens/${item.name}`}
                    />
                  ))}
                </Head>
                <ChargerConnection queryClient={queryClient} />
                <Announcements queryClient={queryClient} />
                {getLayout(<Component {...pageProps} />, queryClient)}
                <ToastContainer />
                {(process.env.NODE_ENV == 'production' || process.env.NODE_ENV == 'test') && (
                  <Script
                    src={
                      typeof window !== 'undefined' ? `${window.location.origin}/scripts/maze.js` : '/scripts/maze.js'
                    }
                  />
                )}
              </PiwikProvider>
            </AuthProvider>
          </ErrorBoundary>
        </LoggerProvider>
      </StateProvider>
    </NextIntlClientProvider>
  );
}

type MyAppInitialProps<P = {}> = AppInitialProps<P> & {
  user: UserToken | null;
};

App.getInitialProps = async (context: AppContext): Promise<MyAppInitialProps> => {
  const user = getServerSideUser(context.ctx.req);
  const ctx = await NextApp.getInitialProps(context);

  return { ...ctx, user: user };
};

export default App;
