import { QueryKey, QueryObserverResult, useQuery, UseQueryOptions } from '@tanstack/react-query';
import { Charger, ChargingPointService } from '@/utils/api/services/openapi';

const chargingPointById = 'getChargingPointById';

const fetcher = async (id: string) => ChargingPointService.getChargingPointById(id);

const queryKey = (id?: string | null) => [chargingPointById, id];

const useCharger = (
  id: string,
  options: Omit<UseQueryOptions<Charger, unknown, Charger, QueryKey>, 'initialData'> = {}
): QueryObserverResult<Charger> => {
  const query = useQuery<Charger>({
    queryKey: queryKey(id),
    queryFn: () => fetcher(id),
    ...options
  });

  return { ...query, isLoading: query.isLoading && options.enabled !== false } as QueryObserverResult<Charger>;
};

export { useCharger, fetcher, queryKey };
