import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { datadogLogs, StatusType } from '@datadog/browser-logs';
import { ILogger, LogLevel } from '@microsoft/signalr';
import { useAtomValue } from 'jotai';
import { userAtom } from '@/state/user';
import { isAdministrator, isAdministratorOrCustomerService } from '@/utils/user';
import { v4 } from 'uuid';

type StringToTuple<S extends any, A extends any[] = []> = S extends `${string}{${string}${infer Rest}`
  ? StringToTuple<Rest, [...A, any]>
  : A;

interface LoggingContextType extends ILogger {
  debug: <S extends string>(string: S, ...values: StringToTuple<S>) => void;
  info: <S extends string>(string: S, ...values: StringToTuple<S>) => void;
  warn: <S extends string>(string: S, ...values: StringToTuple<S>) => void;
  error: <S extends string>(string: S, ...values: StringToTuple<S>) => void;
  addContext: (context: object) => {
    warn: <S extends string>(message: S, ...values: StringToTuple<S>) => void;
    debug: <S extends string>(message: S, ...values: StringToTuple<S>) => void;
    error: <S extends string>(message: S, ...values: StringToTuple<S>) => void;
    info: <S extends string>(message: S, ...values: StringToTuple<S>) => void;
  };
}

enum DatadogEnv {
  Local = 'local',
  Development = 'development',
  Preview = 'preview',
  Test = 'test',
  Production = 'production'
}

const config = {
  clientToken: 'pub5c257120f1ad3198adad2d490ba019ac',
  site: 'datadoghq.eu',
  service: 'portal-v2',
  forwardErrorsToLogs: true,
  sampleRate: 100
};

const LoggerContext = createContext<LoggingContextType>({
  debug: () => () => {},
  info: () => () => {},
  warn: () => () => {},
  error: () => () => {},
  addContext: () => ({
    debug: () => {},
    info: () => {},
    warn: () => {},
    error: () => {}
  }),
  log: () => {}
});

const useLogger = () => useContext(LoggerContext);

function withLogger<T>(Component: React.ComponentType<T & { logger: ReturnType<typeof useLogger> }>) {
  return function WrappedComponent(props: any) {
    const logger = useLogger();
    return <Component {...props} logger={logger} />;
  };
}

const LoggerProvider: React.FC<{
  children: React.ReactNode;
  env: DatadogEnv;
  version: string;
}> = ({ children, env, version }) => {
  const user = useAtomValue(userAtom);
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    if (initialized) {
      datadogLogs.logger.setLevel('info');
      if (process.env.NODE_ENV === 'development') {
        datadogLogs.logger.setHandler(['http', 'console']);
      }
      // Set tabId
      const tabId = sessionStorage.tabID ? sessionStorage.tabID : (sessionStorage.tabID = v4());
      datadogLogs.setGlobalContextProperty('TabId', tabId);
    }
  }, [initialized]);

  useEffect(() => {
    datadogLogs.setGlobalContextProperty('user', {
      isLoggedIn: user != undefined,
      isAdmin: isAdministrator(user?.role),
      isCustomerService: isAdministratorOrCustomerService(user?.role),
      userId: user?.baseAccountExternalId == undefined ? user?.externalId : user?.baseAccountExternalId,
      impersonatedId: user?.baseAccountExternalId != undefined ? user?.externalId : undefined
    });

    return () => datadogLogs.removeGlobalContextProperty('user');
  }, [user]);

  useEffect(() => {
    try {
      if (initialized || !env || !version) {
        // eslint-disable-next-line no-console
        console.warn('Error initializing Datadog, already initalized or missing env/versiong', env, version);
        return;
      }

      datadogLogs.init({
        ...config,
        env: env,
        version: version.replace('+', '_'),
        beforeSend: log => {
          const xhrFetchErrorPattern =
            /^(XHR|Fetch) (error) (GET|OPTIONS|POST|PUT|PATCH|DELETE) (https?:?\/\/[\w\-]+(\.[\w\-]+)+[\/\w\-\.\?\=\&]*)/;

          if (log.message.startsWith('console error: Failed to evaluate variable - CJS - Checkform - Kontaktskjema:')) {
            return false;
          } else if (log.message === 'Network Error') {
            return false;
          } else if (log.message === 'XHR error POST https://aneo.piwik.pro/ppms.php') {
            return false;
          } else if (log.message.startsWith('console error: Error: Abort fetching component for route:')) {
            log.status = 'info';
          } else if (xhrFetchErrorPattern.test(log.message)) {
            // We no longer log XHR/Fetch error messages like this as we log them ourselves in the Axios interceptor
            return false;
          } else if (log.message.startsWith("ReferenceError: Can't find variable:")) {
            log.MessageTemplate = "ReferenceError: Can't find variable: {variable}";
          }

          if (log.MessageTemplate == undefined) {
            log.MessageTemplate = log.message;
          }
        }
      });

      setInitialized(true);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.warn('Error initializing Datadog', error);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const _: (str: string, values: any[]) => [string, object] = useCallback((str, values) => {
    const context: { [index: string]: any } = {};

    const interpolatedString = str.replace(/\$?\{(.+?)\}/g, (match, p1) => {
      const value = values.shift();
      context[p1] = value;
      return value;
    });

    return [interpolatedString, context];
  }, []);

  const log = useCallback(
    (message: string, context?: object, type?: StatusType) => {
      if (initialized) {
        try {
          datadogLogs.logger.log(message, context, type);
        } catch (error) {
          // eslint-disable-next-line no-console
          console.warn('Error logging to Datadog', error);
        }
      }
    },
    [initialized]
  );

  const _debug = useCallback(
    <S extends string>(message: S, context?: object, ...values: StringToTuple<S>) => {
      const [interpolatedMessage, interpolatedContext] = _(message, values);

      log(
        interpolatedMessage,
        {
          Properties: {
            ...context,
            ...interpolatedContext
          },
          MessageTemplate: message
        },
        'debug'
      );
    },
    [_, log]
  );

  const debug = useCallback(
    <S extends string>(message: S, ...values: StringToTuple<S>) => {
      _debug(message, undefined, ...values);
    },
    [_debug]
  );

  const _info = useCallback(
    <S extends string>(message: S, context?: object, ...values: StringToTuple<S>) => {
      const [interpolatedMessage, interpolatedContext] = _(message, values);

      log(
        interpolatedMessage,
        {
          Properties: {
            ...context,
            ...interpolatedContext
          },
          MessageTemplate: message
        },
        'info'
      );
    },
    [_, log]
  );

  const info = useCallback(
    <S extends string>(message: S, ...values: StringToTuple<S>) => {
      _info(message, undefined, ...values);
    },
    [_info]
  );

  const _warn = useCallback(
    <S extends string>(message: S, context?: object, ...values: StringToTuple<S>) => {
      const [interpolatedMessage, interpolatedContext] = _(message, values);

      log(
        interpolatedMessage,
        {
          Properties: {
            ...context,
            ...interpolatedContext
          },
          MessageTemplate: message
        },
        'warn'
      );
    },
    [_, log]
  );

  const warn = useCallback(
    <S extends string>(message: S, ...values: StringToTuple<S>) => {
      _warn(message, undefined, ...values);
    },
    [_warn]
  );

  const _error = useCallback(
    <S extends string>(message: S, context?: object, ...values: StringToTuple<S>) => {
      const [interpolatedMessage, interpolatedContext] = _(message, values);

      log(
        interpolatedMessage,
        {
          Properties: {
            ...context,
            ...interpolatedContext
          },
          MessageTemplate: message
        },
        'error'
      );
    },
    [_, log]
  );

  const error = useCallback(
    <S extends string>(message: S, ...values: StringToTuple<S>) => {
      _error(message, undefined, ...values);
    },
    [_error]
  );

  // This logging function is (and only should be used by SignlarR)
  const ILoggerLog = useCallback(
    (logLevel: LogLevel, message: string) => {
      switch (logLevel) {
        case LogLevel.Critical:
        case LogLevel.Error:
        case LogLevel.Warning:
        case LogLevel.Information:
        case LogLevel.Debug:
        case LogLevel.Trace:
        case LogLevel.None:
        default:
          debug(message);
          break;
      }
    },
    [debug]
  );

  const addContext = useCallback(
    (context: object) => {
      return {
        debug: <S extends string>(message: S, ...values: StringToTuple<S>) => _debug(message, context, ...values),
        info: <S extends string>(message: S, ...values: StringToTuple<S>) => _info(message, context, ...values),
        warn: <S extends string>(message: S, ...values: StringToTuple<S>) => _warn(message, context, ...values),
        error: <S extends string>(message: S, ...values: StringToTuple<S>) => _error(message, context, ...values)
      };
    },
    [_debug, _error, _info, _warn]
  );

  const value = useMemo(
    () => ({ debug, info, warn, error, addContext, log: ILoggerLog }),
    [ILoggerLog, addContext, debug, error, info, warn]
  );

  return <LoggerContext.Provider value={value}>{children}</LoggerContext.Provider>;
};

export { DatadogEnv, withLogger, useLogger, LoggerProvider };
export type { LoggingContextType };
