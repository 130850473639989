import { usePathname as useNextPathname } from 'next/navigation';
import { useLocale } from 'next-intl';

const usePathname: (options?: { includeLocale: boolean }) => string | null = (options = { includeLocale: true }) => {
  const locale = useLocale();
  let pathname = useNextPathname();

  if (!options.includeLocale && pathname != null) {
    pathname = pathname.replace(`${locale}/`, '');
  }

  return pathname;
};

export { usePathname };
