import * as React from 'react';
import { cn } from '@/utils/utils';
import Logo from '@/public/assets/logo.svg';
import { Typography } from '@/components/Typography';
import { Link } from '@/utils/translation/navigation';
import { DesktopHeaderMenu } from '@/components/header/DesktopHeaderMenu';
import { useTranslation } from '@/utils/translation/useTranslation';

enum DesktopHeaderVariant {
  Transparent = 'Transparent',
  White = 'White',
  Black = 'Black',
  Yellow = 'Yellow'
}

interface Props {
  variant?: DesktopHeaderVariant;
  guest?: boolean;
  showLoginButton?: boolean;
  className?: string;
}

const DesktopHeader: React.FC<Props> = ({
  variant = DesktopHeaderVariant.Black,
  guest = true,
  showLoginButton = true,
  className
}) => {
  const { t } = useTranslation();

  return (
    <header
      className={cn(
        'h-[5.5rem]',
        'w-full',
        'py-2.5',
        'px-10',
        'flex',
        'items-center',
        'gap-2',
        {
          'bg-transparent': variant == DesktopHeaderVariant.Transparent,
          'bg-common-white': variant == DesktopHeaderVariant.White,
          'bg-common-black': variant == DesktopHeaderVariant.Black,
          'bg-yellow-100': variant == DesktopHeaderVariant.Yellow
        },
        className
      )}
    >
      <div className={cn('grow', 'basis-0', 'flex', 'w-fit')}>
        <a aria-label={t('global:aneoMobilityLink')} href={t('global:aneoMobilityUrl')}>
          <Logo
            className={cn('fill-yellow-100', {
              'fill-common-black':
                variant == DesktopHeaderVariant.Transparent ||
                variant == DesktopHeaderVariant.White ||
                variant == DesktopHeaderVariant.Yellow,
              'fill-yellow-100': variant == DesktopHeaderVariant.Black
            })}
          />
        </a>
      </div>
      <div className={cn('flex', 'gap-8', 'justify-center', 'items-center')}>
        {!guest && <DesktopHeaderMenu />}
        {guest && showLoginButton && (
          <Link href="/auth/login">
            <Typography
              type="L-B"
              component="span"
              className={cn('underline', 'whitespace-nowrap', {
                'text-common-black':
                  variant == DesktopHeaderVariant.Transparent || variant == DesktopHeaderVariant.Yellow,
                'text-yellow-100': variant == DesktopHeaderVariant.Black
              })}
            >
              {t('global:header.desktop.login')}
            </Typography>
          </Link>
        )}
      </div>
    </header>
  );
};

export { DesktopHeader, DesktopHeaderVariant };
