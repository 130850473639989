/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Charger } from '../models/Charger';
import type { ResetChargingStationCommand } from '../models/ResetChargingStationCommand';
import type { SChargingPointDTO } from '../models/SChargingPointDTO';
import type { SetAccessControlCommand } from '../models/SetAccessControlCommand';
import type { SetCableLockCommand } from '../models/SetCableLockCommand';
import type { StartTransactionCommand } from '../models/StartTransactionCommand';
import type { StopTransactionCommand } from '../models/StopTransactionCommand';
import type { SyncFreeChargingForSubscriptionCommand } from '../models/SyncFreeChargingForSubscriptionCommand';
import type { ToggleCableLockCommand } from '../models/ToggleCableLockCommand';
import type { ToggleFreeChargingCommand } from '../models/ToggleFreeChargingCommand';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ChargingPointService {

    /**
     * @param id
     * @returns Charger Success
     * @throws ApiError
     */
    public static getChargingPointById(
        id: string,
    ): CancelablePromise<Charger> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/chargingpoint/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param subscriptionId
     * @returns Charger Success
     * @throws ApiError
     */
    public static getChargingPointsBySubscription(
        subscriptionId: string,
    ): CancelablePromise<Array<Charger>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/chargingpoint/subscription/{subscriptionId}',
            path: {
                'subscriptionId': subscriptionId,
            },
        });
    }

    /**
     * @param requestBody
     * @returns any Success
     * @throws ApiError
     */
    public static stopTransaction(
        requestBody?: StopTransactionCommand,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/chargingpoint/transaction/stop',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns any Success
     * @throws ApiError
     */
    public static startTransaction(
        requestBody?: StartTransactionCommand,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/chargingpoint/transaction/start',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns any Success
     * @throws ApiError
     */
    public static resetChargingStation(
        requestBody?: ResetChargingStationCommand,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/chargingpoint/reset',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns any Success
     * @throws ApiError
     */
    public static toggleLock(
        requestBody?: ToggleCableLockCommand,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/chargingpoint/toggle-lock',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns any Success
     * @throws ApiError
     */
    public static setCableLock(
        requestBody: SetCableLockCommand,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/chargingpoint/set-cable-lock',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns any Success
     * @throws ApiError
     */
    public static toggleFreeCharging(
        requestBody?: ToggleFreeChargingCommand,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/chargingpoint/toggle-free-charging',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns any Success
     * @throws ApiError
     */
    public static setAccessControl(
        requestBody: SetAccessControlCommand,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/chargingpoint/set-access-control',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id
     * @returns SChargingPointDTO Success
     * @throws ApiError
     */
    public static getChargerByParkingLotId(
        id: string,
    ): CancelablePromise<SChargingPointDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/chargingpoint/parking-lot/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param requestBody
     * @returns any Success
     * @throws ApiError
     */
    public static syncFreeCharging(
        requestBody?: SyncFreeChargingForSubscriptionCommand,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/chargingpoint/sync-freecharging',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
